import { default as _91_46_46_46route_45path_93OBJaTvhwakMeta } from "/home/vsts/work/1/s/pages/[...route-path].vue?macro=true";
import { default as searchMkXoykAuUKMeta } from "/home/vsts/work/1/s/pages/search.vue?macro=true";
export default [
  {
    name: "routepath",
    path: "/:routepath(.*)*",
    component: () => import("/home/vsts/work/1/s/pages/[...route-path].vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: searchMkXoykAuUKMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/search.vue").then(m => m.default || m)
  }
]